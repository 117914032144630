//global style and changing variables

@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700|Lato:300,900');


/*----------------PRIMARY COLORS----------------*/

$orange: rgb(212, 85, 37);
$orange-bis:rgb(255, 168, 79);

$light-orange : rgb(255, 136, 86);

$dark-orange: rgb(150, 54, 19);
$dark-orange-bis : rgb(152, 47, 8);

$light-gray: rgb(244, 248, 244);
$yellow-bis:rgb(243,202,64);
/*----------------MATCH STATUS COLORS----------------*/
$green: rgb(0,176,240);
$red: rgb(255,0,0);
$yellow : rgb(255,255,0);
$orange_bis : rgb(255,192,0);


// Update Bulma's global variables
$family-sans-serif: "Lato", sans-serif;
$primary: $orange;
$link: $orange;

// Update some of Bulma's component variables
$body-background-color: white;
$control-border-width: 2px;
$input-border-color: transparent;
$input-border-color: $light-gray;
$input-shadow: none;

